import React, {Component} from "react";
import {Button, Form, Grid, Header, Image, Modal} from "semantic-ui-react";
import {connect} from "react-redux";
import {rejectIfAnyNull} from "../controllers/utilities/env-checks";
import AndroidComm from "../controllers/utilities/unity-android-communication";
import {formatQuery} from "../controllers/utilities/string-utils";
import {getAdItem, setAdItem, getAdItemByEventId, ImgVideo, ImgVideoSmall} from "../components/lib/ad-manage-api-lib";
import {navigate} from "../../.cache/gatsby-browser-entry";
import {getUA} from "react-device-detect";
import "../styles/bg.css";
import {AdIosaregionSwipetoslide} from "../components/react-slick/ad-iosaregion-swipetoslide";
import {Link} from "gatsby";
import KaisakuApi from "../controllers/kaisaku-api";
import cookie from "react-cookies";
import zdlbg from "../images/main/zdlbg.png";

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings,
    };
})(class extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            userId:'',
            gameId:'',
            eventId: '',
            accessToken: '',
            nickname:'',
            createdAt:'',
            expireAt:'',
            expireAt_TTL:'',
            divStyle: '',
            maxCount: 0,
            saleCount: 0,
            transactionId:"",
            buyId:"",
            province: "安徽",
            city: "合肥",
            county: "長豐",
            provinces: ['安徽',  '北京', '福建', '甘肅', '廣東', '廣西', '貴州', '海南', '河北', '河南', '黑龍江', '湖北', '湖南', '吉林', '江蘇', '江西', '遼寧', '內蒙古', '寧夏', '青海', '山東', '山西', '陜西', '上海', '四川', '天津', '西藏', '新疆', '雲南', '浙江', '重慶'],
            cities: ['合肥'],
            counties: ['長豐'],

            userName:"",
            userTel:"",
            userMail:"",

            provincesAddess:"",
            cityAddess:"",
            countyAddess:"",

            detailAddess:"",
            message: "",
            messageTop: "",
            mode: '',
            body: {}
        };
        let { userId ,gameId, accessToken, nickname, createdAt, expireAt, expireAt_TTL, transactionId, buyId, mode} = formatQuery(this.props.location.search);
        this.state.userId = userId;
        this.state.gameId = gameId;
        this.state.accessToken = accessToken;
        this.state.nickname = nickname;
        this.state.createdAt = createdAt;
        this.state.expireAt = expireAt;
        this.state.expireAt_TTL = expireAt_TTL;
        this.state.transactionId = transactionId;
        this.state.buyId = buyId;
        this.state.mode = mode;



    }

    getCity(province) {
        let gc1 = [];
        gc1['安徽'] = ['合肥', '安慶', '蚌埠', '亳州', '巢湖', '池州', '滁州', '阜陽', '淮北', '淮南', '黃山', '六安', '馬鞍山', '宿州', '銅陵', '蕪湖', '宣城'];
        gc1['北京'] = ['昌平', '朝陽區', '崇文', '大興', '東城', '房山', '豐臺', '海澱', '懷柔', '門頭溝', '密雲', '平谷', '石景山', '順義', '通州區', '西城', '宣武', '延慶'];
        gc1['福建'] = ['福州', '龍巖', '南平', '寧德', '莆田', '泉州', '三明', '廈門', '漳州'];
        gc1['甘肅'] = ['蘭州', '白銀', '定西', '甘南', '嘉峪關', '金昌', '酒泉', '臨夏', '隴南', '平涼', '慶陽', '天水', '武威', '張掖'];
        gc1['廣東'] = ['廣州', '潮州', '東莞', '佛山', '河源', '惠州', '江門', '揭陽', '茂名', '梅州', '清遠', '汕頭', '汕尾', '韶關', '深圳', '陽江', '雲浮', '湛江', '肇慶', '中山', '珠海'];
        gc1['廣西'] = ['桂林', '百色', '北海', '崇左', '防城港', '貴港', '河池', '賀州', '來賓', '柳州', '南寧', '欽州', '梧州', '玉林'];
        gc1['貴州'] = ['貴陽', '安順', '畢節', '六盤水', '黔東南', '黔南', '黔西南', '銅仁', '遵義'];
        gc1['海南'] = ['海口', '白沙', '保亭', '昌江', '澄邁', '儋州', '定安', '東方', '樂東', '臨高', '陵水', '南沙群島', '瓊海', '瓊中', '三亞', '屯昌', '萬寧', '文昌', '五指山', '西沙群島', '中沙群島'];
        gc1['河北'] = ['石家莊', '保定', '滄州', '承德', '邯鄲', '衡水', '廊坊', '秦皇島', '唐山', '邢臺', '張家口'];
        gc1['河南'] = ['鄭州', '安陽', '鶴壁', '焦作', '濟源', '開封', '洛陽', '漯河', '南陽', '平頂山', '濮陽', '三門峽', '商丘', '新鄉', '信陽', '許昌', '周口', '駐馬店'];
        gc1['黑龍江'] = ['哈爾濱', '大慶', '大興安嶺', '鶴崗', '黑河', '雞西', '佳木斯', '牡丹江', '七臺河', '齊齊哈爾', '雙鴨山', '綏化', '伊春'];
        gc1['湖北'] = ['武漢', '鄂州', '恩施', '黃岡', '黃石', '荊門', '荊州', '潛江', '神農架', '十堰', '隨州', '天門', '仙桃', '鹹寧', '襄樊', '孝感', '宜昌'];
        gc1['湖南'] = ['長沙', '常德', '郴州', '衡陽', '懷化', '婁底', '邵陽', '湘潭', '湘西', '益陽', '永州', '嶽陽', '張家界', '株洲'];
        gc1['吉林'] = ['長春', '白城', '白山', '吉林', '遼源', '四平', '松原', '通化', '延邊'];
        gc1['江蘇'] = ['南京', '常州', '淮安', '連雲港', '南通', '蘇州', '宿遷', '泰州', '無錫', '徐州', '鹽城', '揚州', '鎮江'];
        gc1['江西'] = ['南昌', '撫州', '贛州', '吉安', '景德鎮', '九江', '萍鄉', '上饒', '新余', '宜春', '鷹潭'];
        gc1['遼寧'] = ['沈陽', '鞍山', '本溪', '朝陽市', '大連', '丹東', '撫順', '阜新', '葫蘆島', '錦州', '遼陽', '盤錦', '鐵嶺', '營口'];
        gc1['內蒙古'] = ['呼和浩特', '阿拉善', '巴彥淖爾', '包頭', '赤峰', '鄂爾多斯', '呼倫貝爾', '通遼', '烏海', '烏蘭察布', '錫林郭勒', '興安'];
        gc1['寧夏'] = ['銀川', '固原', '石嘴山', '吳忠', '中衛'];
        gc1['青海'] = ['西寧', '果洛', '海北', '海東', '海南', '海西', '黃南', '玉樹'];
        gc1['山東'] = ['濟南', '濱州', '德州', '東營', '菏澤', '濟寧', '萊蕪', '聊城', '臨沂', '青島', '日照', '泰安', '威海', '濰坊', '煙臺', '棗莊', '淄博'];
        gc1['山西'] = ['太原', '長治', '大同', '晉城', '晉中', '臨汾', '呂梁', '朔州', '忻州', '陽泉', '運城'];
        gc1['陜西'] = ['西安', '安康', '寶雞', '漢中', '商洛', '銅川', '渭南', '鹹陽', '延安', '榆林'];
        gc1['上海'] = ['寶山', '長寧', '崇明', '奉賢', '虹口', '黃浦', '嘉定', '金山', '靜安', '盧灣', '閔行', '南匯', '浦東', '普陀', '青浦', '松江', '徐匯', '楊浦', '閘北'];
        gc1['四川'] = ['成都', '阿壩', '巴中', '達州', '德陽', '甘孜', '廣安', '廣元', '樂山', '涼山', '瀘州', '眉山', '綿陽', '內江', '南充', '攀枝花', '遂寧', '雅安', '宜賓', '資陽', '自貢'];
        gc1['天津'] = ['寶坻', '北辰', '大港', '東麗', '漢沽', '和平', '河北', '河東', '河西', '紅橋', '薊縣', '津南', '靜海', '南開', '寧河', '塘沽', '武清', '西青'];
        gc1['西藏'] = ['拉薩', '阿裏', '昌都', '林芝', '那曲', '日喀則', '山南'];
        gc1['新疆'] = ['烏魯木齊', '阿克蘇', '阿拉爾', '阿勒泰', '巴音郭楞', '博爾塔拉', '昌吉', '哈密', '和田', '喀什', '克拉瑪依', '克孜勒蘇柯爾克孜', '石河子', '塔城', '圖木舒克', '吐魯番', '五家渠', '伊犁'];
        gc1['雲南'] = ['昆明', '保山', '楚雄', '大理', '德宏', '迪慶', '紅河', '麗江', '臨滄', '怒江', '曲靖', '思茅', '文山', '西雙版納', '玉溪', '昭通'];
        gc1['浙江'] = ['杭州', '湖州', '嘉興', '金華', '麗水', '寧波', '衢州', '紹興', '臺州', '溫州', '舟山'];
        gc1['重慶'] = ['巴南', '北碚', '璧山', '長壽', '城口', '大渡口', '大足', '墊江', '豐都', '奉節', '涪陵', '合川', '江北', '江津', '九龍坡', '開縣', '梁平', '南岸', '南川', '彭水', '綦江', '黔江', '榮昌', '沙坪壩', '石柱', '雙橋', '銅梁', '潼南', '萬盛', '萬州', '巫山', '巫溪', '武隆', '秀山', '永川', '酉陽', '渝北', '渝中', '雲陽', '忠縣'];
        return gc1[province]
    }

    getCounty(province, city) {
        let gc2 = [];
        gc2['安徽'] = [];
        gc2['安徽']['合肥'] = ['長豐', '肥東', '肥西', '合肥市'];
        gc2['安徽']['安慶'] = ['安慶市', '樅陽', '懷寧', '潛山', '宿松', '太湖', '桐城', '望江', '嶽西'];
        gc2['安徽']['蚌埠'] = ['蚌埠市', '固鎮', '懷遠', '五河'];
        gc2['安徽']['亳州'] = ['亳州市', '利辛', '蒙城', '渦陽'];
        gc2['安徽']['巢湖'] = ['巢湖市', '含山', '和縣', '廬江', '無為'];
        gc2['安徽']['池州'] = ['池州市', '東至', '青陽', '石臺'];
        gc2['安徽']['滁州'] = ['滁州市', '定遠', '鳳陽', '來安', '明光', '全椒', '天長'];
        gc2['安徽']['阜陽'] = ['阜南', '阜陽市', '界首', '臨泉', '太和', '潁上'];
        gc2['安徽']['淮北'] = ['淮北市', '濉溪'];
        gc2['安徽']['淮南'] = ['鳳臺', '淮南市'];
        gc2['安徽']['黃山'] = ['黃山市', '祁門', '歙縣', '休寧', '黟縣'];
        gc2['安徽']['六安'] = ['霍邱', '霍山', '金寨', '六安市', '壽縣', '舒城'];
        gc2['安徽']['馬鞍山'] = ['當塗', '馬鞍山市'];
        gc2['安徽']['宿州'] = ['碭山', '靈璧', '泗縣', '宿州市', '蕭縣'];
        gc2['安徽']['銅陵'] = ['銅陵市', '銅陵縣'];
        gc2['安徽']['蕪湖'] = ['繁昌', '南陵', '蕪湖市', '蕪湖縣'];
        gc2['安徽']['宣城'] = ['廣德', '績溪', '涇縣', '旌德', '郎溪', '寧國', '宣城市'];
        gc2['北京'] = [];
        gc2['北京']['昌平'] = ['昌平'];
        gc2['北京']['朝陽區'] = ['朝陽區'];
        gc2['北京']['崇文'] = ['崇文'];
        gc2['北京']['大興'] = ['大興'];
        gc2['北京']['東城'] = ['東城'];
        gc2['北京']['房山'] = ['房山'];
        gc2['北京']['豐臺'] = ['豐臺'];
        gc2['北京']['海澱'] = ['海澱'];
        gc2['北京']['懷柔'] = ['懷柔'];
        gc2['北京']['門頭溝'] = ['門頭溝'];
        gc2['北京']['密雲'] = ['密雲'];
        gc2['北京']['平谷'] = ['平谷'];
        gc2['北京']['石景山'] = ['石景山'];
        gc2['北京']['順義'] = ['順義'];
        gc2['北京']['通州區'] = ['通州區'];
        gc2['北京']['西城'] = ['西城'];
        gc2['北京']['宣武'] = ['宣武'];
        gc2['北京']['延慶'] = ['延慶'];
        gc2['福建'] = [];
        gc2['福建']['福州'] = ['長樂', '福清', '福州市', '連江', '羅源', '閩侯', '閩清', '平潭', '永泰'];
        gc2['福建']['龍巖'] = ['長汀', '連城', '龍巖市', '上杭', '武平', '永定', '漳平'];
        gc2['福建']['南平'] = ['光澤', '建甌', '建陽', '南平市', '浦城', '邵武', '順昌', '松溪', '武夷山', '政和'];
        gc2['福建']['寧德'] = ['福安', '福鼎', '古田', '寧德市', '屏南', '壽寧', '霞浦', '柘榮', '周寧'];
        gc2['福建']['莆田'] = ['莆田市', '仙遊'];
        gc2['福建']['泉州'] = ['安溪', '德化', '惠安', '金門', '晉江', '南安', '泉州市', '石獅', '永春'];
        gc2['福建']['三明'] = ['大田', '建寧', '將樂', '明溪', '寧化', '清流', '三明市', '沙縣', '泰寧', '永安', '尤溪'];
        gc2['福建']['廈門'] = ['廈門市'];
        gc2['福建']['漳州'] = ['長泰', '東山', '華安', '龍海', '南靖', '平和', '雲霄', '漳浦', '漳州市', '詔安'];
        gc2['甘肅'] = [];
        gc2['甘肅']['蘭州'] = ['臯蘭', '蘭州市', '永登', '榆中'];
        gc2['甘肅']['白銀'] = ['白銀市', '會寧', '景泰', '靖遠'];
        gc2['甘肅']['定西'] = ['定西市', '臨洮', '隴西', '岷縣', '通渭', '渭源', '漳縣'];
        gc2['甘肅']['甘南'] = ['叠部', '合作', '臨潭', '碌曲', '瑪曲', '夏河', '舟曲', '卓尼'];
        gc2['甘肅']['嘉峪關'] = ['嘉峪關市'];
        gc2['甘肅']['金昌'] = ['金昌市', '永昌'];
        gc2['甘肅']['酒泉'] = ['阿克塞', '敦煌', '瓜州', '金塔', '酒泉市', '肅北', '玉門'];
        gc2['甘肅']['臨夏'] = ['東鄉族', '廣河', '和政', '積石山', '康樂', '臨夏市', '臨夏縣', '永靖'];
        gc2['甘肅']['隴南'] = ['成縣', '宕昌', '徽縣', '康縣', '禮縣', '兩當', '隴南市', '文縣', '西和'];
        gc2['甘肅']['平涼'] = ['崇信', '華亭', '涇川', '靜寧', '靈臺', '平涼市', '莊浪'];
        gc2['甘肅']['慶陽'] = ['合水', '華池', '環縣', '寧縣', '慶城', '慶陽市', '鎮原', '正寧'];
        gc2['甘肅']['天水'] = ['甘谷', '秦安', '清水', '天水市', '武山', '張家川'];
        gc2['甘肅']['武威'] = ['古浪', '民勤', '天祝', '武威市'];
        gc2['甘肅']['張掖'] = ['高臺', '臨澤', '民樂', '山丹', '肅南', '張掖市'];
        gc2['廣東'] = [];
        gc2['廣東']['廣州'] = ['從化', '廣州市', '增城'];
        gc2['廣東']['潮州'] = ['潮安', '潮州市', '饒平'];
        gc2['廣東']['東莞'] = ['東莞'];
        gc2['廣東']['佛山'] = ['佛山市'];
        gc2['廣東']['河源'] = ['東源', '和平縣', '河源市', '連平', '龍川', '紫金'];
        gc2['廣東']['惠州'] = ['博羅', '惠東', '惠州市', '龍門'];
        gc2['廣東']['江門'] = ['恩平', '鶴山', '江門市', '開平', '臺山'];
        gc2['廣東']['揭陽'] = ['惠來', '揭東', '揭西', '揭陽市', '普寧'];
        gc2['廣東']['茂名'] = ['電白', '高州', '化州', '茂名市', '信宜'];
        gc2['廣東']['梅州'] = ['大埔', '豐順', '蕉嶺', '梅縣', '梅州市', '平遠', '五華', '興寧'];
        gc2['廣東']['清遠'] = ['佛岡', '連南', '連山', '連州', '清新', '清遠市', '陽山', '英德'];
        gc2['廣東']['汕頭'] = ['南澳', '汕頭市'];
        gc2['廣東']['汕尾'] = ['海豐', '陸豐', '陸河', '汕尾市'];
        gc2['廣東']['韶關'] = ['樂昌', '南雄', '仁化', '乳源', '韶關市', '始興', '翁源', '新豐'];
        gc2['廣東']['深圳'] = ['深圳市'];
        gc2['廣東']['陽江'] = ['陽春', '陽東', '陽江市', '陽西'];
        gc2['廣東']['雲浮'] = ['羅定', '新興', '郁南', '雲安', '雲浮市'];
        gc2['廣東']['湛江'] = ['雷州', '廉江', '遂溪', '吳川', '徐聞', '湛江市'];
        gc2['廣東']['肇慶'] = ['德慶', '封開', '高要', '廣寧', '懷集', '四會', '肇慶市'];
        gc2['廣東']['中山'] = ['中山市'];
        gc2['廣東']['珠海'] = ['珠海市'];
        gc2['廣西'] = [];
        gc2['廣西']['桂林'] = ['恭城', '灌陽', '桂林市', '荔浦', '臨桂', '靈川', '龍勝', '平樂', '全州', '興安', '陽朔', '永福', '資源'];
        gc2['廣西']['百色'] = ['百色市', '德保', '靖西', '樂業', '淩雲', '隆林', '那坡', '平果', '田東', '田林', '田陽', '西林'];
        gc2['廣西']['北海'] = ['北海市', '合浦'];
        gc2['廣西']['崇左'] = ['崇左市', '大新', '扶綏', '龍州', '寧明', '憑祥', '天等'];
        gc2['廣西']['防城港'] = ['東興', '防城港市', '上思'];
        gc2['廣西']['貴港'] = ['貴港市', '桂平', '平南'];
        gc2['廣西']['河池'] = ['巴馬', '大化', '東蘭', '都安', '鳳山', '河池市', '環江', '羅城', '南丹', '天峨', '宜州'];
        gc2['廣西']['賀州'] = ['富川', '賀州市', '昭平', '鐘山'];
        gc2['廣西']['來賓'] = ['合山', '金秀', '來賓市', '武宣', '象州', '忻城'];
        gc2['廣西']['柳州'] = ['柳城', '柳江', '柳州市', '鹿寨', '融安', '融水', '三江'];
        gc2['廣西']['南寧'] = ['賓陽', '橫縣', '隆安', '馬山', '南寧市', '上林', '武鳴'];
        gc2['廣西']['欽州'] = ['靈山', '浦北', '欽州市'];
        gc2['廣西']['梧州'] = ['蒼梧', '岑溪', '蒙山', '藤縣', '梧州市'];
        gc2['廣西']['玉林'] = ['北流', '博白', '陸川', '容縣', '興業', '玉林市'];
        gc2['貴州'] = [];
        gc2['貴州']['貴陽'] = ['貴陽市', '開陽', '清鎮', '息烽', '修文'];
        gc2['貴州']['安順'] = ['安順市', '關嶺', '平壩', '普定', '鎮寧', '紫雲'];
        gc2['貴州']['畢節'] = ['畢節市', '大方', '赫章', '金沙', '納雍', '黔西', '威寧', '織金'];
        gc2['貴州']['六盤水'] = ['六盤水市', '六枝', '盤縣', '水城'];
        gc2['貴州']['黔東南'] = ['岑鞏', '從江', '丹寨', '黃平', '劍河', '錦屏', '凱裏', '雷山', '黎平', '麻江', '榕江', '三穗', '施秉', '臺江', '天柱', '鎮遠'];
        gc2['貴州']['黔南'] = ['長順', '都勻', '獨山', '福泉', '貴定', '惠水', '荔波', '龍裏', '羅甸', '平塘', '三都', '甕安'];
        gc2['貴州']['黔西南'] = ['安龍', '冊亨', '普安', '晴隆', '望謨', '興仁', '興義', '貞豐'];
        gc2['貴州']['銅仁'] = ['德江', '江口', '石阡', '思南', '松桃', '銅仁市', '萬山', '沿河', '印江', '玉屏'];
        gc2['貴州']['遵義'] = ['赤水', '道真', '鳳岡', '湄潭', '仁懷', '綏陽', '桐梓', '務川', '習水', '余慶', '正安', '遵義市', '遵義縣'];
        gc2['海南'] = [];
        gc2['海南']['海口'] = ['海口'];
        gc2['海南']['白沙'] = ['白沙'];
        gc2['海南']['保亭'] = ['保亭'];
        gc2['海南']['昌江'] = ['昌江'];
        gc2['海南']['澄邁'] = ['澄邁'];
        gc2['海南']['儋州'] = ['儋州'];
        gc2['海南']['定安'] = ['定安'];
        gc2['海南']['東方'] = ['東方'];
        gc2['海南']['樂東'] = ['樂東'];
        gc2['海南']['臨高'] = ['臨高'];
        gc2['海南']['陵水'] = ['陵水'];
        gc2['海南']['南沙群島'] = ['南沙群島'];
        gc2['海南']['瓊海'] = ['瓊海'];
        gc2['海南']['瓊中'] = ['瓊中'];
        gc2['海南']['三亞'] = ['三亞'];
        gc2['海南']['屯昌'] = ['屯昌'];
        gc2['海南']['萬寧'] = ['萬寧'];
        gc2['海南']['文昌'] = ['文昌'];
        gc2['海南']['五指山'] = ['五指山'];
        gc2['海南']['西沙群島'] = ['西沙群島'];
        gc2['海南']['中沙群島'] = ['中沙群島'];
        gc2['河北'] = [];
        gc2['河北']['石家莊'] = ['槁城', '晉州', '井陘', '靈壽', '鹿泉', '欒城', '平山', '深澤', '石家莊市', '無極', '辛集', '新樂', '行唐', '元氏', '贊皇', '趙縣', '正定', '高邑'];
        gc2['河北']['保定'] = ['安國', '安新', '保定市', '博野', '定興', '定州', '阜平', '高碑店', '高陽', '淶水', '淶源', '蠡縣', '滿城', '清苑', '曲陽', '容城', '順平', '唐縣', '望都', '雄縣', '徐水', '易縣', '涿州'];
        gc2['河北']['滄州'] = ['泊頭', '滄縣', '滄州市', '東光', '海興', '河間', '黃驊', '孟村', '南皮', '青縣', '任丘', '肅寧', '吳橋', '獻縣', '鹽山'];
        gc2['河北']['承德'] = ['承德市', '承德縣', '豐寧', '寬城', '隆化', '灤平', '平泉', '圍場', '興隆'];
        gc2['河北']['邯鄲'] = ['成安', '磁縣', '大名', '肥鄉', '館陶', '廣平', '邯鄲市', '邯鄲縣', '雞澤', '臨漳', '邱縣', '曲周', '涉縣', '魏縣', '武安', '永年'];
        gc2['河北']['衡水'] = ['安平', '阜城', '故城', '衡水市', '冀州', '景縣', '饒陽', '深州', '武強', '武邑', '棗強'];
        gc2['河北']['廊坊'] = ['霸州', '大廠', '大城', '固安', '廊坊市', '三河', '文安', '香河', '永清'];
        gc2['河北']['秦皇島'] = ['昌黎', '撫寧', '盧龍', '秦皇島市', '青龍'];
        gc2['河北']['唐山'] = ['樂亭', '灤南', '灤縣', '遷安', '遷西', '唐海', '唐山市', '玉田', '遵化'];
        gc2['河北']['邢臺'] = ['柏鄉', '廣宗', '巨鹿', '臨城', '臨西', '隆堯', '內丘', '南宮', '南和', '寧晉', '平鄉', '清河', '任縣', '沙河', '威縣', '新河', '邢臺市', '邢臺縣'];
        gc2['河北']['張家口'] = ['赤城', '崇禮', '沽源', '懷安', '懷來', '康保', '尚義', '萬全', '蔚縣', '宣化', '陽原', '張北', '張家口市', '涿鹿'];
        gc2['河南'] = [];
        gc2['河南']['鄭州'] = ['登封', '鞏義', '新密', '新鄭', '滎陽', '鄭州市', '中牟'];
        gc2['河南']['安陽'] = ['安陽市', '安陽縣', '滑縣', '林州', '內黃', '湯陰'];
        gc2['河南']['鶴壁'] = ['鶴壁市', '浚縣', '淇縣'];
        gc2['河南']['焦作'] = ['博愛', '焦作市', '孟州', '沁陽', '溫縣', '武陟', '修武'];
        gc2['河南']['濟源'] = ['濟源市'];
        gc2['河南']['開封'] = ['開封市', '開封縣', '蘭考', '杞縣', '通許', '尉氏'];
        gc2['河南']['洛陽'] = ['欒川', '洛寧', '洛陽市', '孟津', '汝陽', '嵩縣', '新安', '偃師', '伊川', '宜陽'];
        gc2['河南']['漯河'] = ['臨潁', '漯河市', '舞陽'];
        gc2['河南']['南陽'] = ['鄧州', '方城', '內鄉', '南陽市', '南召', '社旗', '唐河', '桐柏', '西峽', '淅川', '新野', '鎮平'];
        gc2['河南']['平頂山'] = ['寶豐', '郟縣', '魯山', '平頂山市', '汝州', '舞鋼', '葉縣'];
        gc2['河南']['濮陽'] = ['範縣', '南樂', '濮陽市', '濮陽縣', '清豐', '臺前'];
        gc2['河南']['三門峽'] = ['靈寶', '盧氏', '三門峽市', '陜縣', '澠池', '義馬'];
        gc2['河南']['商丘'] = ['民權', '寧陵', '商丘市', '睢縣', '夏邑', '永城', '虞城', '柘城'];
        gc2['河南']['新鄉'] = ['長垣', '封丘', '輝縣', '獲嘉', '衛輝', '新鄉市', '新鄉縣', '延津', '原陽'];
        gc2['河南']['信陽'] = ['固始', '光山', '淮濱', '潢川', '羅山', '商城', '息縣', '新縣', '信陽市'];
        gc2['河南']['許昌'] = ['長葛', '襄城', '許昌市', '許昌縣', '鄢陵', '禹州'];
        gc2['河南']['周口'] = ['鄲城', '扶溝', '淮陽', '鹿邑', '商水', '沈丘', '太康', '西華', '項城', '周口市'];
        gc2['河南']['駐馬店'] = ['泌陽', '平輿', '確山', '汝南', '上蔡', '遂平', '西平', '新蔡', '正陽', '駐馬店市'];
        gc2['黑龍江'] = [];
        gc2['黑龍江']['哈爾濱'] = ['巴彥', '賓縣', '方正', '哈爾濱市', '木蘭', '尚誌', '雙城', '通河', '五常', '延壽', '依蘭'];
        gc2['黑龍江']['大慶'] = ['大慶市', '杜爾伯特', '林甸', '肇源', '肇州'];
        gc2['黑龍江']['大興安嶺'] = ['呼瑪', '呼中', '加格達奇', '漠河', '松嶺', '塔河', '新林'];
        gc2['黑龍江']['鶴崗'] = ['鶴崗市', '蘿北', '綏濱'];
        gc2['黑龍江']['黑河'] = ['北安', '黑河市', '嫩江', '孫吳', '五大連池', '遜克'];
        gc2['黑龍江']['雞西'] = ['虎林', '雞東', '雞西市', '密山'];
        gc2['黑龍江']['佳木斯'] = ['撫遠', '富錦', '樺川', '樺南', '佳木斯市', '湯原', '同江'];
        gc2['黑龍江']['牡丹江'] = ['東寧', '海林', '林口', '牡丹江市', '穆棱', '寧安', '綏芬河'];
        gc2['黑龍江']['七臺河'] = ['勃利', '七臺河'];
        gc2['黑龍江']['齊齊哈爾'] = ['拜泉', '富裕', '甘南', '克東', '克山', '龍江', '訥河', '齊齊哈爾市', '泰來', '依安'];
        gc2['黑龍江']['雙鴨山'] = ['寶清', '集賢', '饒河', '雙鴨山市', '友誼'];
        gc2['黑龍江']['綏化'] = ['安達', '海倫', '蘭西', '明水', '青岡', '慶安', '綏化市', '綏棱', '望奎', '肇東'];
        gc2['黑龍江']['伊春'] = ['嘉蔭', '鐵力', '伊春市'];
        gc2['湖北'] = [];
        gc2['湖北']['武漢'] = ['武漢市'];
        gc2['湖北']['鄂州'] = ['鄂州市'];
        gc2['湖北']['恩施'] = ['巴東', '恩施市', '鶴峰', '建始', '來鳳', '利川', '鹹豐', '宣恩'];
        gc2['湖北']['黃岡'] = ['紅安', '黃岡市', '黃梅', '羅田', '麻城', '蘄春', '團風', '武穴', '浠水', '英山'];
        gc2['湖北']['黃石'] = ['大冶', '黃石', '陽新'];
        gc2['湖北']['荊門'] = ['京山', '荊門市', '沙洋', '鐘祥'];
        gc2['湖北']['荊州'] = ['公安', '洪湖', '監利', '江陵', '荊州市', '石首', '松滋'];
        gc2['湖北']['潛江'] = ['潛江'];
        gc2['湖北']['神農架'] = ['神農架'];
        gc2['湖北']['十堰'] = ['丹江口', '房縣', '十堰市', '鄖西', '鄖縣', '竹山', '竹溪'];
        gc2['湖北']['隨州'] = ['廣水', '隨州市'];
        gc2['湖北']['天門'] = ['天門市'];
        gc2['湖北']['仙桃'] = ['仙桃市'];
        gc2['湖北']['鹹寧'] = ['赤壁', '崇陽', '嘉魚', '通城', '通山', '鹹寧市'];
        gc2['湖北']['襄樊'] = ['保康', '谷城', '老河口', '南漳', '襄樊市', '宜城', '棗陽'];
        gc2['湖北']['孝感'] = ['安陸', '大悟', '漢川', '孝昌', '孝感市', '應城', '雲夢'];
        gc2['湖北']['宜昌'] = ['長陽', '當陽', '五峰', '興山', '宜昌市', '宜都', '遠安', '枝江', '秭歸'];
        gc2['湖南'] = [];
        gc2['湖南']['長沙'] = ['長沙市', '長沙縣', '瀏陽', '寧鄉', '望城'];
        gc2['湖南']['常德'] = ['安鄉', '常德市', '漢壽', '津市', '澧縣', '臨澧', '石門', '桃源'];
        gc2['湖南']['郴州'] = ['安仁', '郴州市', '桂東', '桂陽', '嘉禾', '臨武', '汝城', '宜章', '永興', '資興'];
        gc2['湖南']['衡陽'] = ['常寧', '衡東', '衡南', '衡山', '衡陽市', '衡陽縣', '耒陽', '祁東'];
        gc2['湖南']['懷化'] = ['辰溪', '洪江', '懷化市', '會同', '靖州', '麻陽', '通道', '新晃', '漵浦', '沅陵', '芷江', '中方'];
        gc2['湖南']['婁底'] = ['冷水江', '漣源', '婁底市', '雙峰', '新化'];
        gc2['湖南']['邵陽'] = ['城步', '洞口', '隆回', '邵東', '邵陽市', '邵陽縣', '綏寧', '武岡', '新寧', '新邵'];
        gc2['湖南']['湘潭'] = ['韶山', '湘潭市', '湘潭縣', '湘鄉'];
        gc2['湖南']['湘西'] = ['保靖', '鳳凰', '古丈', '花垣', '吉首', '龍山', '瀘溪', '永順'];
        gc2['湖南']['益陽'] = ['安化', '南縣', '桃江', '益陽市', '沅江'];
        gc2['湖南']['永州'] = ['道縣', '東安', '江華', '江永', '藍山', '寧遠', '祁陽', '雙牌', '新田', '永州市'];
        gc2['湖南']['嶽陽'] = ['華容', '臨湘', '汨羅', '平江', '湘陰', '嶽陽市', '嶽陽縣'];
        gc2['湖南']['張家界'] = ['慈利', '桑植', '張家界市'];
        gc2['湖南']['株洲'] = ['茶陵', '醴陵', '炎陵', '攸縣', '株洲市', '株洲縣'];
        gc2['吉林'] = [];
        gc2['吉林']['長春'] = ['長春市', '德惠', '九臺', '農安', '榆樹'];
        gc2['吉林']['白城'] = ['白城市', '大安', '洮南', '通榆', '鎮賚'];
        gc2['吉林']['白山'] = ['白山', '長白', '撫松', '靖宇', '臨江'];
        gc2['吉林']['吉林'] = ['樺甸', '吉林市', '蛟河', '磐石', '舒蘭', '永吉'];
        gc2['吉林']['遼源'] = ['東豐', '東遼', '遼源市'];
        gc2['吉林']['四平'] = ['公主嶺', '梨樹', '雙遼', '四平市', '伊通'];
        gc2['吉林']['松原'] = ['長嶺', '扶余', '前郭爾羅斯', '乾安', '松原市'];
        gc2['吉林']['通化'] = ['輝南', '集安', '柳河', '梅河口', '通化市', '通化縣'];
        gc2['吉林']['延邊'] = ['延吉市', '敦化', '和龍', '琿春', '龍井', '圖們', '汪清', '安圖'];
        gc2['江蘇'] = [];
        gc2['江蘇']['南京'] = ['高淳', '溧水', '南京市'];
        gc2['江蘇']['常州'] = ['常州市', '金壇', '溧陽'];
        gc2['江蘇']['淮安'] = ['洪澤', '淮安市', '金湖', '漣水', '盱眙'];
        gc2['江蘇']['連雲港'] = ['東海', '贛榆', '灌南', '灌雲', '連雲港市'];
        gc2['江蘇']['南通'] = ['海安', '海門', '南通市', '啟東', '如東', '如臯', '通州'];
        gc2['江蘇']['蘇州'] = ['常熟', '昆山', '蘇州市', '太倉', '吳江', '張家港'];
        gc2['江蘇']['宿遷'] = ['沭陽', '泗洪', '泗陽', '宿遷市'];
        gc2['江蘇']['泰州'] = ['姜堰', '靖江', '泰興', '泰州市', '興化'];
        gc2['江蘇']['無錫'] = ['江陰', '無錫市', '宜興'];
        gc2['江蘇']['徐州'] = ['豐縣', '沛縣', '邳州', '睢寧', '銅山', '新沂', '徐州市'];
        gc2['江蘇']['鹽城'] = ['濱海', '大豐', '東臺', '阜寧', '建湖', '射陽', '響水', '鹽城市'];
        gc2['江蘇']['揚州'] = ['寶應', '高郵', '江都', '揚州市', '儀征'];
        gc2['江蘇']['鎮江'] = ['丹陽', '句容', '揚中', '鎮江市'];
        gc2['江西'] = [];
        gc2['江西']['南昌'] = ['安義', '進賢', '南昌市', '南昌縣', '新建'];
        gc2['江西']['撫州'] = ['崇仁', '東鄉', '撫州市', '廣昌', '金溪', '樂安', '黎川', '南城', '南豐', '宜黃', '資溪'];
        gc2['江西']['贛州'] = ['安遠', '崇義', '大余', '定南', '贛縣', '贛州市', '會昌', '龍南', '南康', '寧都', '全南', '瑞金', '上猶', '石城', '信豐', '興國', '尋烏', '於都'];
        gc2['江西']['吉安'] = ['安福', '吉安市', '吉安縣', '吉水', '井岡山', '遂川', '泰和', '萬安', '峽江', '新幹', '永豐', '永新'];
        gc2['江西']['景德鎮'] = ['浮梁', '景德鎮市', '樂平'];
        gc2['江西']['九江'] = ['德安', '都昌', '湖口', '九江市', '九江縣', '彭澤', '瑞昌', '武寧', '星子', '修水', '永修'];
        gc2['江西']['萍鄉'] = ['蓮花', '蘆溪', '萍鄉市', '上栗'];
        gc2['江西']['上饒'] = ['德興', '廣豐', '橫峰', '鄱陽', '鉛山', '上饒市', '上饒縣', '萬年', '婺源', '弋陽', '余幹', '玉山'];
        gc2['江西']['新余'] = ['分宜', '新余市'];
        gc2['江西']['宜春'] = ['豐城', '奉新', '高安', '靖安', '上高', '銅鼓', '萬載', '宜春市', '宜豐', '樟樹'];
        gc2['江西']['鷹潭'] = ['貴溪', '鷹潭市', '余江'];
        gc2['遼寧'] = [];
        gc2['遼寧']['沈陽'] = ['法庫', '康平', '遼中', '沈陽市', '新民'];
        gc2['遼寧']['鞍山'] = ['鞍山市', '海城', '臺安', '岫巖'];
        gc2['遼寧']['本溪'] = ['本溪市', '本溪縣', '桓仁'];
        gc2['遼寧']['朝陽市'] = ['北票', '朝陽市', '朝陽縣', '建平', '喀喇沁左翼', '淩源'];
        gc2['遼寧']['大連'] = ['長海', '大連市', '普蘭店', '瓦房店', '莊河'];
        gc2['遼寧']['丹東'] = ['丹東市', '東港', '鳳城', '寬甸'];
        gc2['遼寧']['撫順'] = ['撫順市', '撫順縣', '清原', '新賓'];
        gc2['遼寧']['阜新'] = ['阜新市', '阜新縣', '彰武'];
        gc2['遼寧']['葫蘆島'] = ['葫蘆島市', '建昌', '綏中', '興城'];
        gc2['遼寧']['錦州'] = ['北鎮', '黑山', '錦州市', '淩海', '義縣'];
        gc2['遼寧']['遼陽'] = ['燈塔', '遼陽市', '遼陽縣'];
        gc2['遼寧']['盤錦'] = ['大窪', '盤錦市', '盤山'];
        gc2['遼寧']['鐵嶺'] = ['昌圖', '調兵山', '開原', '鐵嶺市', '鐵嶺縣', '西豐'];
        gc2['遼寧']['營口'] = ['大石橋', '蓋州', '營口市'];
        gc2['內蒙古'] = [];
        gc2['內蒙古']['呼和浩特'] = ['和林格爾', '呼和浩特市', '清水河', '土默特左旗', '托克托', '武川'];
        gc2['內蒙古']['阿拉善'] = ['阿拉善右旗', '阿拉善左旗', '額濟納旗'];
        gc2['內蒙古']['巴彥淖爾'] = ['巴彥淖爾市', '磴口', '杭錦後旗', '烏拉特後旗', '烏拉特前旗', '烏拉特中旗', '五原'];
        gc2['內蒙古']['包頭'] = ['包頭市', '達爾罕茂明安聯合旗', '固陽', '土默特右旗'];
        gc2['內蒙古']['赤峰'] = ['阿魯科爾沁旗', '敖漢旗', '巴林右旗', '巴林左旗', '赤峰市', '喀喇沁旗', '克什克騰旗', '林西', '寧城', '翁牛特旗'];
        gc2['內蒙古']['鄂爾多斯'] = ['達拉特旗', '鄂爾多斯市', '鄂托克旗', '鄂托克前旗', '杭錦旗', '烏審旗', '伊金霍洛旗', '準格爾旗'];
        gc2['內蒙古']['呼倫貝爾'] = ['阿榮旗', '陳巴爾虎旗', '額爾古納', '鄂倫春旗', '鄂溫克族旗', '根河', '呼倫貝爾市', '滿洲裏', '莫力達瓦旗', '新巴爾虎右旗', '新巴爾虎左旗', '牙克石', '紮蘭屯'];
        gc2['內蒙古']['通遼'] = ['霍林郭勒', '開魯', '科爾沁左翼後旗', '科爾沁左翼中旗', '庫倫旗', '奈曼旗', '通遼市', '紮魯特旗'];
        gc2['內蒙古']['烏海'] = ['烏海'];
        gc2['內蒙古']['烏蘭察布'] = ['察哈爾右翼後旗', '察哈爾右翼前旗', '察哈爾右翼中旗', '豐鎮', '化德', '涼城', '商都', '四子王旗', '烏蘭察布市', '興和', '卓資'];
        gc2['內蒙古']['錫林郭勒'] = ['阿巴嘎旗', '東烏珠穆沁旗', '多倫', '二連浩特', '蘇尼特右旗', '蘇尼特左旗', '太仆寺旗', '西烏珠穆沁旗', '錫林郭勒市', '鑲黃旗', '正藍旗', '正鑲白旗'];
        gc2['內蒙古']['興安'] = ['阿爾山', '科爾沁右翼前旗', '科爾沁右翼中旗', '突泉', '烏蘭浩特', '紮賚特旗'];
        gc2['寧夏'] = [];
        gc2['寧夏']['銀川'] = ['賀蘭', '靈武', '銀川市', '永寧'];
        gc2['寧夏']['固原'] = ['固原市', '涇源', '隆德', '彭陽', '西吉'];
        gc2['寧夏']['石嘴山'] = ['平羅', '石嘴山市'];
        gc2['寧夏']['吳忠'] = ['青銅峽', '同心', '吳忠市', '鹽池'];
        gc2['寧夏']['中衛'] = ['海原', '中寧', '中衛市'];
        gc2['青海'] = [];
        gc2['青海']['西寧'] = ['大通', '湟源', '湟中', '西寧市'];
        gc2['青海']['果洛'] = ['班瑪', '達日', '甘德', '久治', '瑪多', '瑪沁'];
        gc2['青海']['海北'] = ['剛察', '海晏', '門源', '祁連'];
        gc2['青海']['海東'] = ['互助', '化隆', '樂都', '民和', '平安', '循化'];
        gc2['青海']['海南'] = ['共和', '貴德', '貴南', '同德', '興海'];
        gc2['青海']['海西'] = ['大柴旦', '德令哈', '都蘭', '格爾木', '冷湖', '茫崖', '天峻', '烏蘭'];
        gc2['青海']['黃南'] = ['河南', '尖紮', '同仁', '澤庫'];
        gc2['青海']['玉樹'] = ['稱多', '囊謙', '曲麻萊', '玉樹', '雜多', '治多'];
        gc2['山東'] = [];
        gc2['山東']['濟南'] = ['濟南市', '濟陽', '平陰', '商河', '章丘'];
        gc2['山東']['濱州'] = ['濱州市', '博興', '惠民', '無棣', '陽信', '沾化', '鄒平'];
        gc2['山東']['德州'] = ['德州市', '樂陵', '臨邑', '陵縣', '寧津', '平原', '齊河', '慶雲', '武城', '夏津', '禹城'];
        gc2['山東']['東營'] = ['東營市', '廣饒', '墾利', '利津'];
        gc2['山東']['菏澤'] = ['曹縣', '成武', '單縣', '定陶', '東明', '菏澤市', '巨野', '鄄城', '鄆城'];
        gc2['山東']['濟寧'] = ['濟寧市', '嘉祥', '金鄉', '梁山', '曲阜', '泗水', '微山', '汶上', '兗州', '魚臺', '鄒城'];
        gc2['山東']['萊蕪'] = ['萊蕪市'];
        gc2['山東']['聊城'] = ['茌平', '東阿', '高唐', '冠縣', '聊城市', '臨清', '莘縣', '陽谷'];
        gc2['山東']['臨沂'] = ['蒼山', '費縣', '莒南', '臨沭', '臨沂市', '蒙陰', '平邑', '郯城', '沂南', '沂水'];
        gc2['山東']['青島'] = ['即墨', '膠南', '膠州', '萊西', '平度', '青島市'];
        gc2['山東']['日照'] = ['莒縣', '日照市', '五蓮'];
        gc2['山東']['泰安'] = ['東平', '肥城', '寧陽', '泰安市', '新泰'];
        gc2['山東']['威海'] = ['榮成', '乳山', '威海市', '文登'];
        gc2['山東']['濰坊'] = ['安丘', '昌樂', '昌邑', '高密', '臨朐', '青州', '壽光', '濰坊市', '諸城'];
        gc2['山東']['煙臺'] = ['長島', '海陽', '萊陽', '萊州', '龍口', '蓬萊', '棲霞', '煙臺市', '招遠'];
        gc2['山東']['棗莊'] = ['滕州', '棗莊市'];
        gc2['山東']['淄博'] = ['高青', '桓臺', '沂源', '淄博市'];
        gc2['山西'] = [];
        gc2['山西']['太原'] = ['古交', '婁煩', '清徐', '太原市', '陽曲'];
        gc2['山西']['長治'] = ['長治市', '長治縣', '長子', '壺關', '黎城', '潞城', '平順', '沁縣', '沁源', '屯留', '武鄉', '襄垣'];
        gc2['山西']['大同'] = ['大同市', '大同縣', '廣靈', '渾源', '靈丘', '天鎮', '陽高', '左雲'];
        gc2['山西']['晉城'] = ['高平', '晉城市', '陵川', '沁水', '陽城', '澤州'];
        gc2['山西']['晉中'] = ['和順', '介休', '晉中市', '靈石', '平遙', '祁縣', '壽陽', '太谷', '昔陽', '榆社', '左權'];
        gc2['山西']['臨汾'] = ['安澤', '大寧', '汾西', '浮山', '古縣', '洪洞', '侯馬', '霍州', '吉縣', '臨汾市', '蒲縣', '曲沃', '隰縣', '鄉寧', '襄汾', '翼城', '永和'];
        gc2['山西']['呂梁'] = ['方山', '汾陽', '交城', '交口', '嵐縣', '臨縣', '柳林', '呂梁市', '石樓', '文水', '孝義', '興縣', '中陽'];
        gc2['山西']['朔州'] = ['懷仁', '山陰', '朔州市', '應縣', '右玉'];
        gc2['山西']['忻州'] = ['保德', '代縣', '定襄', '繁峙', '河曲', '靜樂', '岢嵐', '寧武', '偏關', '神池', '五臺', '五寨', '忻州市', '原平'];
        gc2['山西']['陽泉'] = ['平定', '陽泉市', '盂縣'];
        gc2['山西']['運城'] = ['河津', '稷山', '絳縣', '臨猗', '平陸', '芮城', '萬榮', '聞喜', '夏縣', '新絳', '永濟', '垣曲', '運城市'];
        gc2['陜西'] = [];
        gc2['陜西']['西安'] = ['高陵', '戶縣', '藍田', '西安市', '周至'];
        gc2['陜西']['安康'] = ['安康市', '白河', '漢陰', '嵐臯', '寧陜', '平利', '石泉', '旬陽', '鎮坪', '紫陽'];
        gc2['陜西']['寶雞'] = ['寶雞市', '鳳縣', '鳳翔', '扶風', '麟遊', '隴縣', '眉縣', '岐山', '千陽', '太白'];
        gc2['陜西']['漢中'] = ['城固', '佛坪', '漢中市', '留壩', '略陽', '勉縣', '南鄭', '寧強', '西鄉', '洋縣', '鎮巴'];
        gc2['陜西']['商洛'] = ['丹鳳', '洛南', '山陽', '商洛市', '商南', '鎮安', '柞水'];
        gc2['陜西']['銅川'] = ['銅川市', '宜君'];
        gc2['陜西']['渭南'] = ['白水', '澄城', '大荔', '富平', '韓城', '合陽', '華縣', '華陰', '蒲城', '潼關', '渭南市'];
        gc2['陜西']['鹹陽'] = ['彬縣', '長武', '淳化', '涇陽', '禮泉', '乾縣', '三原', '武功', '鹹陽市', '興平', '旬邑', '永壽'];
        gc2['陜西']['延安'] = ['安塞', '富縣', '甘泉', '黃陵', '黃龍', '洛川', '吳起', '延安市', '延長', '延川', '宜川', '誌丹', '子長'];
        gc2['陜西']['榆林'] = ['定邊', '府谷', '橫山', '佳縣', '靖邊', '米脂', '清澗', '神木', '綏德', '吳堡', '榆林市', '子洲'];
        gc2['上海'] = [];
        gc2['上海']['寶山'] = ['寶山'];
        gc2['上海']['長寧'] = ['長寧'];
        gc2['上海']['崇明'] = ['崇明'];
        gc2['上海']['奉賢'] = ['奉賢'];
        gc2['上海']['虹口'] = ['虹口'];
        gc2['上海']['黃浦'] = ['黃浦'];
        gc2['上海']['嘉定'] = ['嘉定'];
        gc2['上海']['金山'] = ['金山'];
        gc2['上海']['靜安'] = ['靜安'];
        gc2['上海']['盧灣'] = ['盧灣'];
        gc2['上海']['閔行'] = ['閔行'];
        gc2['上海']['南匯'] = ['南匯'];
        gc2['上海']['浦東'] = ['浦東'];
        gc2['上海']['普陀'] = ['普陀'];
        gc2['上海']['青浦'] = ['青浦'];
        gc2['上海']['松江'] = ['松江'];
        gc2['上海']['徐匯'] = ['徐匯'];
        gc2['上海']['楊浦'] = ['楊浦'];
        gc2['上海']['閘北'] = ['閘北'];
        gc2['四川'] = [];
        gc2['四川']['成都'] = ['成都市', '崇州', '大邑', '都江堰', '金堂', '彭州', '郫縣', '蒲江', '邛崍', '雙流', '新津'];
        gc2['四川']['阿壩'] = ['阿壩', '黑水', '紅原', '金川', '九寨溝', '理縣', '馬爾康', '茂縣', '壤塘', '若爾蓋', '松潘', '汶川', '小金'];
        gc2['四川']['巴中'] = ['巴中市', '南江', '平昌', '通江'];
        gc2['四川']['達州'] = ['達縣', '達州市', '大竹', '開江', '渠縣', '萬源', '宣漢'];
        gc2['四川']['德陽'] = ['德陽市', '廣漢', '羅江', '綿竹', '什邡', '中江'];
        gc2['四川']['甘孜'] = ['巴塘', '白玉', '丹巴', '道孚', '稻城', '得榮', '德格', '甘孜', '九龍', '康定', '理塘', '瀘定', '爐霍', '色達', '石渠', '鄉城', '新龍', '雅江'];
        gc2['四川']['廣安'] = ['廣安市', '華鎣', '鄰水', '武勝', '嶽池'];
        gc2['四川']['廣元'] = ['蒼溪', '廣元市', '劍閣', '青川', '旺蒼'];
        gc2['四川']['樂山'] = ['峨邊', '峨眉山', '夾江', '犍為', '井研', '樂山市', '馬邊', '沐川'];
        gc2['四川']['涼山'] = ['布拖', '德昌', '甘洛', '會東', '會理', '金陽', '雷波', '美姑', '冕寧', '木裏', '寧南', '普格', '西昌', '喜德', '鹽源', '越西', '昭覺'];
        gc2['四川']['瀘州'] = ['古藺', '合江', '瀘縣', '瀘州市', '敘永'];
        gc2['四川']['眉山'] = ['丹棱', '洪雅', '眉山市', '彭山', '青神', '仁壽'];
        gc2['四川']['綿陽'] = ['安縣', '北川', '江油', '綿陽市', '平武', '三臺', '鹽亭', '梓潼'];
        gc2['四川']['內江'] = ['隆昌', '內江市', '威遠', '資中'];
        gc2['四川']['南充'] = ['閬中', '南部', '南充市', '蓬安', '西充', '儀隴', '營山'];
        gc2['四川']['攀枝花'] = ['米易', '攀枝花市', '鹽邊'];
        gc2['四川']['遂寧'] = ['大英', '蓬溪', '射洪', '遂寧市'];
        gc2['四川']['雅安'] = ['寶興', '漢源', '蘆山', '名山', '石棉', '天全', '雅安市', '滎經'];
        gc2['四川']['宜賓'] = ['長寧縣', '高縣', '珙縣', '江安', '筠連', '南溪', '屏山', '興文', '宜賓市', '宜賓縣'];
        gc2['四川']['資陽'] = ['安嶽', '簡陽', '樂至', '資陽市'];
        gc2['四川']['自貢'] = ['富順', '榮縣', '自貢市'];
        gc2['天津'] = [];
        gc2['天津']['寶坻'] = ['寶坻'];
        gc2['天津']['北辰'] = ['北辰'];
        gc2['天津']['大港'] = ['大港'];
        gc2['天津']['東麗'] = ['東麗'];
        gc2['天津']['漢沽'] = ['漢沽'];
        gc2['天津']['和平'] = ['和平'];
        gc2['天津']['河北'] = ['河北'];
        gc2['天津']['河東'] = ['河東'];
        gc2['天津']['河西'] = ['河西'];
        gc2['天津']['紅橋'] = ['紅橋'];
        gc2['天津']['薊縣'] = ['薊縣'];
        gc2['天津']['津南'] = ['津南'];
        gc2['天津']['靜海'] = ['靜海'];
        gc2['天津']['南開'] = ['南開'];
        gc2['天津']['寧河'] = ['寧河'];
        gc2['天津']['塘沽'] = ['塘沽'];
        gc2['天津']['武清'] = ['武清'];
        gc2['天津']['西青'] = ['西青'];
        gc2['西藏'] = [];
        gc2['西藏']['拉薩'] = ['達孜', '當雄', '堆龍德慶', '拉薩市', '林周', '墨竹工卡', '尼木', '曲水'];
        gc2['西藏']['阿裏'] = ['措勤', '噶爾', '改則', '革吉', '普蘭', '日土', '劄達'];
        gc2['西藏']['昌都'] = ['八宿', '邊壩', '察雅', '昌都', '丁青', '貢覺', '江達', '類烏齊', '洛隆', '芒康', '左貢'];
        gc2['西藏']['林芝'] = ['波密', '察隅', '工布江達', '朗縣', '林芝', '米林', '墨脫'];
        gc2['西藏']['那曲'] = ['安多', '巴青', '班戈', '比如', '嘉黎', '那曲', '尼瑪', '聶榮', '申紮', '索縣'];
        gc2['西藏']['日喀則'] = ['昂仁', '白朗', '定結', '定日', '崗巴', '吉隆', '江孜', '康馬', '拉孜', '南木林', '聶拉木', '仁布', '日喀則市', '薩嘎', '薩迦', '謝通門', '亞東', '仲巴'];
        gc2['西藏']['山南'] = ['措美', '錯那', '貢嘎', '加查', '浪卡子', '隆子', '洛紮', '乃東', '瓊結', '曲松', '桑日', '紮囊'];
        gc2['新疆'] = [];
        gc2['新疆']['烏魯木齊'] = ['烏魯木齊市', '烏魯木齊縣'];
        gc2['新疆']['阿克蘇'] = ['阿克蘇市', '阿瓦提', '拜城', '柯坪', '庫車', '沙雅', '溫宿', '烏什', '新和'];
        gc2['新疆']['阿拉爾'] = ['阿拉爾'];
        gc2['新疆']['阿勒泰'] = ['阿勒泰', '布爾津', '福海', '富蘊', '哈巴河', '吉木乃', '青河'];
        gc2['新疆']['巴音郭楞'] = ['博湖', '和靜', '和碩', '庫爾勒', '輪臺', '且末', '若羌', '尉犁', '焉耆'];
        gc2['新疆']['博爾塔拉'] = ['博樂', '精河', '溫泉'];
        gc2['新疆']['昌吉'] = ['昌吉市', '阜康', '呼圖壁', '吉木薩爾', '瑪納斯', '米泉', '木壘', '奇臺'];
        gc2['新疆']['哈密'] = ['巴裏坤', '哈密市', '伊吾'];
        gc2['新疆']['和田'] = ['策勒', '和田市', '和田縣', '洛浦', '民豐', '墨玉', '皮山', '於田'];
        gc2['新疆']['喀什'] = ['巴楚', '伽師', '喀什市', '麥蓋提', '莎車', '疏附', '疏勒', '塔什庫爾幹塔吉克', '葉城', '英吉沙', '嶽普湖', '澤普'];
        gc2['新疆']['克拉瑪依'] = ['克拉瑪依'];
        gc2['新疆']['克孜勒蘇柯爾克孜'] = ['阿合奇', '阿克陶', '阿圖什', '烏恰'];
        gc2['新疆']['石河子'] = ['石河子'];
        gc2['新疆']['塔城'] = ['額敏', '和布克賽爾', '沙灣', '塔城市', '托裏', '烏蘇', '裕民'];
        gc2['新疆']['圖木舒克'] = ['圖木舒克'];
        gc2['新疆']['吐魯番'] = ['鄯善', '吐魯番市', '托克遜'];
        gc2['新疆']['五家渠'] = ['五家渠'];
        gc2['新疆']['伊犁'] = ['察布查爾錫伯', '鞏留', '霍城', '奎屯', '尼勒克', '特克斯', '新源', '伊寧市', '伊寧縣', '昭蘇'];
        gc2['雲南'] = [];
        gc2['雲南']['昆明'] = ['安寧', '呈貢', '富民', '晉寧', '昆明市', '祿勸', '石林', '嵩明', '尋甸', '宜良'];
        gc2['雲南']['保山'] = ['保山市', '昌寧', '龍陵', '施甸', '騰沖'];
        gc2['雲南']['楚雄'] = ['楚雄市', '大姚', '祿豐', '牟定', '南華', '雙柏', '武定', '姚安', '永仁', '元謀'];
        gc2['雲南']['大理'] = ['賓川', '大理市', '洱源', '鶴慶', '劍川', '彌渡', '南澗', '巍山', '祥雲', '漾濞', '永平', '雲龍'];
        gc2['雲南']['德宏'] = ['梁河', '隴川', '潞西', '瑞麗', '盈江'];
        gc2['雲南']['迪慶'] = ['德欽', '維西', '香格裏拉'];
        gc2['雲南']['紅河'] = ['個舊', '河口', '紅河', '建水', '金平', '開遠', '瀘西', '綠春', '蒙自', '彌勒', '屏邊', '石屏', '元陽'];
        gc2['雲南']['麗江'] = ['華坪', '麗江市', '寧蒗', '永勝', '玉龍'];
        gc2['雲南']['臨滄'] = ['滄源', '鳳慶', '耿馬', '臨滄市', '雙江', '永德', '雲縣', '鎮康'];
        gc2['雲南']['怒江'] = ['福貢', '貢山', '蘭坪', '瀘水'];
        gc2['雲南']['曲靖'] = ['富源', '會澤', '陸良', '羅平', '馬龍', '曲靖市', '師宗', '宣威', '沾益'];
        gc2['雲南']['思茅'] = ['江城', '景東', '景谷', '瀾滄', '孟連', '墨江', '普洱', '思茅市', '西盟', '鎮沅'];
        gc2['雲南']['文山'] = ['富寧', '廣南', '麻栗坡', '馬關', '丘北', '文山', '西疇', '硯山'];
        gc2['雲南']['西雙版納'] = ['景洪', '猛海', '猛臘'];
        gc2['雲南']['玉溪'] = ['澄江', '峨山', '華寧', '江川', '通海', '新平', '易門', '玉溪市', '元江'];
        gc2['雲南']['昭通'] = ['大關', '魯甸', '巧家', '水富', '綏江', '威信', '鹽津', '彜良', '永善', '昭通市', '鎮雄'];
        gc2['浙江'] = [];
        gc2['浙江']['杭州'] = ['淳安', '富陽', '杭州市', '建德', '臨安', '桐廬'];
        gc2['浙江']['湖州'] = ['安吉', '長興', '德清', '湖州市'];
        gc2['浙江']['嘉興'] = ['海寧', '海鹽', '嘉善', '嘉興市', '平湖', '桐鄉'];
        gc2['浙江']['金華'] = ['東陽', '金華市', '蘭溪', '磐安', '浦江', '武義', '義烏', '永康'];
        gc2['浙江']['麗水'] = ['縉雲', '景寧', '麗水市', '龍泉', '青田', '慶元', '松陽', '遂昌', '雲和'];
        gc2['浙江']['寧波'] = ['慈溪', '奉化', '寧波市', '寧海', '象山', '余姚'];
        gc2['浙江']['衢州'] = ['常山', '江山', '開化', '龍遊', '衢州市'];
        gc2['浙江']['紹興'] = ['上虞', '紹興市', '紹興縣', '嵊州', '新昌', '諸暨'];
        gc2['浙江']['臺州'] = ['臨海', '三門', '臺州市', '天臺', '溫嶺', '仙居', '玉環'];
        gc2['浙江']['溫州'] = ['蒼南', '洞頭', '樂清', '平陽', '瑞安', '泰順', '溫州市', '文成', '永嘉'];
        gc2['浙江']['舟山'] = ['岱山', '嵊泗', '舟山市'];
        gc2['重慶'] = [];
        gc2['重慶']['巴南'] = ['巴南'];
        gc2['重慶']['北碚'] = ['北碚'];
        gc2['重慶']['璧山'] = ['璧山'];
        gc2['重慶']['長壽'] = ['長壽'];
        gc2['重慶']['城口'] = ['城口'];
        gc2['重慶']['大渡口'] = ['大渡口'];
        gc2['重慶']['大足'] = ['大足'];
        gc2['重慶']['墊江'] = ['墊江'];
        gc2['重慶']['豐都'] = ['豐都'];
        gc2['重慶']['奉節'] = ['奉節'];
        gc2['重慶']['涪陵'] = ['涪陵'];
        gc2['重慶']['合川'] = ['合川'];
        gc2['重慶']['江北'] = ['江北'];
        gc2['重慶']['江津'] = ['江津'];
        gc2['重慶']['九龍坡'] = ['九龍坡'];
        gc2['重慶']['開縣'] = ['開縣'];
        gc2['重慶']['梁平'] = ['梁平'];
        gc2['重慶']['南岸'] = ['南岸'];
        gc2['重慶']['南川'] = ['南川'];
        gc2['重慶']['彭水'] = ['彭水'];
        gc2['重慶']['綦江'] = ['綦江'];
        gc2['重慶']['黔江'] = ['黔江'];
        gc2['重慶']['榮昌'] = ['榮昌'];
        gc2['重慶']['沙坪壩'] = ['沙坪壩'];
        gc2['重慶']['石柱'] = ['石柱'];
        gc2['重慶']['雙橋'] = ['雙橋'];
        gc2['重慶']['銅梁'] = ['銅梁'];
        gc2['重慶']['潼南'] = ['潼南'];
        gc2['重慶']['萬盛'] = ['萬盛'];
        gc2['重慶']['萬州'] = ['萬州'];
        gc2['重慶']['巫山'] = ['巫山'];
        gc2['重慶']['巫溪'] = ['巫溪'];
        gc2['重慶']['武隆'] = ['武隆'];
        gc2['重慶']['秀山'] = ['秀山'];
        gc2['重慶']['永川'] = ['永川'];
        gc2['重慶']['酉陽'] = ['酉陽'];
        gc2['重慶']['渝北'] = ['渝北'];
        gc2['重慶']['渝中'] = ['渝中'];
        gc2['重慶']['雲陽'] = ['雲陽'];
        gc2['重慶']['忠縣'] = ['忠縣'];
        return gc2[province][city]
    }

    async componentDidMount(){
        // 是否有销售数据,有显示,无,跳回游戏
        const body = cookie.load("_body_data");
        if ((body) && (body !== "")){
            this.state.body = body;
            console.log(body);
            // this.state.userName = this.state.body.userName;
            // this.state.userTel = this.state.body.userTel;
            // this.state.userMail = this.state.body.userMail;
            // this.state.provincesAddess = this.state.body.provincesAddess;
            // this.state.cityAddess = this.state.body.cityAddess;
            // this.state.countyAddess = this.state.body.countyAddess;
            //
            // this.state.province = this.state.body.provincesAddess;
            // this.state.city = this.state.body.cityAddess;
            // this.state.county = this.state.body.countyAddess;
            // // cookie.save("_body_data", "");
            // this.setState({
            //     userName : this.state.body.userName,
            //     userTel : this.state.body.userTel,
            //     userMail : this.state.body.userMail
            // })
        }
    }


    handleChange(name, e) {
        e.preventDefault()
        switch (name) {

            case "province":
                this.setState({
                    province: e.target.value,
                    cities: this.getCity(e.target.value),
                    city: this.getCity(e.target.value)[0],
                    counties: this.getCounty(e.target.value, this.getCity(e.target.value)[0]),
                    county: this.getCounty(e.target.value, this.getCity(e.target.value)[0])[0]
                });
                break;
            case "city":
                this.setState({
                    city: e.target.value,
                    counties: this.getCounty(this.state.province, e.target.value),
                    county: this.getCounty(this.state.province, e.target.value)[0]
                });
                break;
            case "county":
                this.setState({
                    county: e.target.value
                });
                break;
            default:
                alert("child handleChange error")
        }

    }

    render() {
        let id = 0;
        return (
            <Grid textAlign='center' style={{
                height: "100vh",
                background: "transparent",
                padding: "3vh"
            }} verticalAlign='middle'>
                <Grid.Column style={{maxWidth: 450}}>
                    <Modal
                        style={{width:'289px',borderRadius:'10px 10px 10px 10px'}}
                        open
                    >
                        <div style={{height:'32px',textAlign:'left',fontSize:'14px',fontWeight:'bold',
                            paddingTop:'8px',borderLeft:'1px solid #444',borderTop:'1px solid #444',borderRight:'1px solid #444',
                            borderRadius:'10px 10px 0px 0px',
                            backgroundColor:"#262626",
                            color:"#FFF",
                            opacity:'1'}}

                        >
                            <div style={{color:'#FFF',float:"left", textAlign:"left",paddingLeft:"10px"}}
                                 onClick={() => {
                                     navigate(`/xr-saleing-page1${this.props.location.search}`);
                                 }}
                            >返回</div>
                            <div style={{color:'red',float:"left", textAlign:"center",paddingLeft:"10px",width:"70%"}}>{this.state.messageTop}</div>
                            {(!this.state.mode) && (<div style={{color:'#FFF',float:"right", textAlign:"left",paddingRight:"10px"}}
                                 onClick={() => {
                                     // console.log({ gameId:this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken,nickname: this.state.nickname,createdAt: this.state.createdAt, expireAt: this.state.expireAt });
                                     AndroidComm.sendSession({ gameId:this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken,nickname: this.state.nickname,createdAt: this.state.createdAt, expireAt: this.state.expireAt, expireAt_TTL: this.state.expireAt_TTL });
                                 }}
                            >回到遊戲</div>)}
                        </div>
                        <div style={{
                            width: '289px',
                            borderRadius: '0px 0px 0px 0px',
                            backgroundColor:"#262626",
                            color:"#E4E4E4",
                            display: ''
                        }}>
                            <div style={{width:'289px',height:"60vh",overflowY:"auto",padding:"15px", color:"#E4E4E4",
                            borderLeft:'1px solid #444',borderRight:'1px solid #444',
}}
                            >
                                <div style={{paddingTop:"20px",paddingBottom:"10px",fontSize:"1.5em", fontWeight:"bold"}}>限定大陸地區</div>
                                <div style={{paddingTop:"10px",paddingBottom:"20px",fontSize:"1.2em", fontWeight:"bold"}}>購買後最遲在2022年6月10日前完成發貨</div>

                                <span style={{fontSize:"10px"}}>*號位置請必需填寫</span><br/>

                                <Form.Input
                                    fluid
                                    className="blackDiv"
                                    onChange={(e, data) => this.setState({userName: data.value})} placeholder="請輸入你的姓名*"
                                /><br/>
                                請選擇你的的地址<br/>
                                <select style={{border: "solid 1px #444",  color:"#E4E4E4", fontSize:"16px",width:"100%" ,marginTop:"5px",backgroundColor:"#626262",borderRadius:'4px 4px 4px 4px' }}
                                 onChange={this.handleChange.bind(this, "province")}>
                                    {this.state.provinces.map(province => (
                                        <option value={province} key={id++}>{province}</option>
                                    ))}
                                </select>
                                <br/>
                                <select style={{border: "solid 1px #444", color:"#E4E4E4", fontSize:"16px",width:"48%" ,marginTop:"5px",marginRight:"2%",backgroundColor:"#626262",borderRadius:'4px 4px 4px 4px'}} onChange={this.handleChange.bind(this, "city")}>
                                    {this.state.cities.map(city => (
                                        <option value={city} key={id++}>{city}</option>
                                    ))}
                                </select>
                                <select style={{border: "solid 1px #444", color:"#E4E4E4", fontSize:"16px",width:"48%" ,marginTop:"5px",marginLeft:"2%",backgroundColor:"#626262",borderRadius:'4px 4px 4px 4px'}} onChange={this.handleChange.bind(this, "county")}>
                                    {this.state.counties.map(county => (
                                        <option value={county} key={id++}>{county}</option>
                                    ))}
                                </select><br/><br/>
                                <Form.TextArea
                                    style={{border: "solid 1px #444", color:"#E4E4E4", fontSize:"16px",width:"100%", backgroundColor:"#626262",borderRadius:'4px 4px 4px 4px',                                                                    
                                }}                                
                                    onChange={(e, data) => this.setState({detailAddess: data.value})} placeholder="請輸入你的地址*"
                                /><br/>
                                <Form.Input
                                    fluid
                                    className="blackDiv"
                                    onChange={(e, data) => this.setState({userTel: data.value})} placeholder="請輸入你的電話*"
                                /><br/>
                                <Form.Input
                                    fluid
                                    className="blackDiv"
                                    onChange={(e, data) => this.setState({userMail: data.value})} placeholder="請輸入你的EMAIL"
                                /><br/>
                                <div style={{width:"100%",color:"red",fontSize:"14px"}} dangerouslySetInnerHTML={{ __html:decodeURI(this.state.message)}}></div>
                            </div>

                            <div style={{
                                height: '60px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold',
                                paddingTop: '1.2em', border: '0px',
                                borderRadius: '0px 0px 10px 10px',
                                backgroundImage: `url(${zdlbg})`, backgroundSize: '100% 100%',
                                marginTop: "-3px",
                                marginLeft: "-8px",
                                marginRight: "-8px",
                                marginBottom: "-10px",
                                color: "#FFF",
                                opacity: '1'
                            }}
                                 onClick={async () => {
                                     this.state.provincesAddess = this.state.province;
                                     this.state.cityAddess = this.state.city;
                                     this.state.countyAddess = this.state.county;
                                     // console.log({ gameId:this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken,nickname: this.state.nickname,createdAt: this.state.createdAt, expireAt: this.state.expireAt });
                                     console.log(this.state);
                                     let message = "";
                                     if (!this.state.userName){
                                         message += "姓名必需填寫<br/>";
                                     }else{
                                         if (!validateName(this.state.userName)){
                                             message += "姓名只能填寫中文或英文<br/>";
                                         }
                                     }
                                     if (!this.state.userTel){
                                         message += "電話必需填寫<br/>";
                                     }else{
                                         if (!validateMobile(this.state.userTel)){
                                             message += "電話格式不正確<br/>";
                                         }
                                     }
                                     if (!this.state.provincesAddess){
                                         message += "省份必需填寫<br/>";
                                     }
                                     if (!this.state.cityAddess){
                                         message += "城市必需填寫<br/>";
                                     }
                                     if (!this.state.countyAddess){
                                         message += "地區必需填寫<br/>";
                                     }
                                     if (!this.state.detailAddess){
                                         message += "詳細地址必需填寫<br/>";
                                     }
                                     if (this.state.userMail){
                                         if (!validateEmail(this.state.userMail)){
                                             message += "EMAIL格式不正確<br/>";
                                         }else{
                                             if (!validateEmail2(this.state.userMail)){
                                                 message += "只能使用 .com 或 .net 或 .xyz 的EMAIL<br/>";
                                             }
                                         }
                                     }

                                     if (message) {
                                         console.log(message);
                                         this.setState({message: message});
                                     }else{
                                         this.setState({message: message});
                                         // 提交
                                         const body ={
                                             "transactionId":this.state.transactionId,
                                             "buyId":this.state.buyId,
                                             "userName":this.state.userName,
                                             "userTel":this.state.userTel,
                                             "userMail":this.state.userMail,
                                             "provincesAddess":this.state.provincesAddess,
                                             "cityAddess":this.state.cityAddess,
                                             "countyAddess":this.state.countyAddess,
                                             "detailAddess":this.state.detailAddess
                                         };
                                         console.log(body);
                                         cookie.save("_body_data", JSON.stringify(body));
                                         navigate(`/xr-saleing-page3-2${this.props.location.search}`);
                                         // const res = await KaisakuApi.confirmTransactionForM1(body, this.state.accessToken);
                                         // if (res.status === 200){
                                         //     const data = res.data;
                                         //     console.log(data);
                                         //
                                         //     if (data.success === true){
                                         //         this.setState({
                                         //             message:"購買成功。"
                                         //         })
                                         //     }else{
                                         //         let message = '購買失敗';
                                         //         if ((data.message === "訂單已完成，請聯繫客服確認") || (data.message === "已售罄")){
                                         //             message = data.message;
                                         //         }
                                         //         this.setState({
                                         //             message:message
                                         //         })
                                         //     }
                                         // }else{
                                         //     let message = "購買失敗";
                                         //     if (res.data.message === "訂單已完成，請聯繫客服確認"){
                                         //         message = res.data.message;
                                         //     }
                                         //     this.setState({
                                         //         message: message
                                         //     })
                                         //     // alert("已售罄");
                                         // }
                                     }
                                 }}
                            >
                                <span style={{color:'#FFF'}}>確認</span>
                            </div>

                        </div>
                    </Modal>
                </Grid.Column>
            </Grid>
        );
    }
});

function endWith(str, endStr){
    const d=str.length-endStr.length;
    return (d>=0 && str.lastIndexOf(endStr)==d);
}

function validateEmail2(email){
    if (endWith(email,".com") || endWith(email, ".xyz") || endWith(email, ".net")){
        return true;
    }else{
        return false;
    }
}

function validateEmail(email) {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
}

function validateMobile(moblie) {
    const regex = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    return regex.test(String(moblie));
}

function validateName(str) {
    const regex = /^[\u4e00-\u9fa5_a-zA-Z0-9\s]+$/;
    return regex.test(String(str).toLowerCase());
}